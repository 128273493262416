import axios from '@/plugins/axios'

/**
 * Create insertion orders
 */
const createOrderClaim = async ({
  type,
  // eslint-disable-next-line camelcase
  order_id
}) => {
  try {
    const form = {
      order_id,
      type
    }
    const response = await axios.post('admin/order-claims', form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 *  Change claim status
 */
const changeClaimStatus = async (id, form) => {
  try {
    const response = await axios.put(`admin/order-claims/${id}`, form)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get order claim
 */
const getOrderClaim = async (id) => {
  try {
    const response = await axios.get(`admin/order-claims/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get order claims
 */
const getOrderClaims = async ({
  // eslint-disable-next-line camelcase
  user_id = null,
  // eslint-disable-next-line camelcase
  end_date = null,
  page = 1,
  search = null,
  // eslint-disable-next-line camelcase
  start_date = null,
  status = null
}) => {
  try {
    const params = {
      page: page
    }
    // eslint-disable-next-line camelcase
    if (user_id) params.user_id = user_id
    // eslint-disable-next-line camelcase
    if (end_date) params.end_date = end_date
    // eslint-disable-next-line camelcase
    if (start_date) params.start_date = start_date
    if (search) params.search = search
    if (status) params.status = status

    const response = await axios.get('admin/order-claims', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get sales summary
 */
const getSalesSummary = async ({
  summaryStart = null,
  summaryEnd = null,
  startDatePay = null,
  endDatePay = null
}) => {
  try {
    const params = {}
    if (summaryStart) params.summary_start = summaryStart
    if (summaryEnd) params.summary_end = summaryEnd
    if (startDatePay) params.start_date_pay = startDatePay
    if (endDatePay) params.end_date_pay = endDatePay
    const response = await axios.get('admin/order-claims/summary', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  changeClaimStatus,
  createOrderClaim,
  getOrderClaim,
  getOrderClaims,
  getSalesSummary
}

<template>
  <div class="d-flex flex-column flex-lg-row gap-5">
    <div class="col-12 col-lg-4">
      <!--begin::Card widget 17-->
      <div class="card h-lg-100">
        <!--begin::Header-->
        <div class="card-header pt-5 border-0">
          <!--begin::Title-->
          <div class="card-title d-flex flex-column">
            <!--begin::Info-->
            <div class="d-flex align-items-center">
              <!--begin::Amount-->
              <span
                class="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">{{ data.total_amount_sold / 100 | formatMoney }}</span>
              <!--end::Amount-->
            </div>
            <!--end::Info-->
            <!--begin::Subtitle-->
            <span class="text-gray-400 pt-1 fw-semibold fs-6">Valor Total Vendido</span>
            <!--end::Subtitle-->
          </div>
          <!--end::Title-->
        </div>
        <!--end::Header-->
        <!--begin::Card body-->
        <div class="card-body d-flex pt-2 pb-4 ">
          <!--begin::Chart-->
          <DoughnutChart
            :chart-options="{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'right'
                }
              }
            }"
            :chart-data="chartDataTotalSold"
            chart-id="doughnut-chart"
            dataset-id-key="label"
            :height="100"
            :width="300"
          />
          <!--end::Chart-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card widget 17-->
    </div>
    <div class="col-12 col-lg-4">
      <!--begin::Card widget 2-->
      <div class="card h-lg-100">
        <!--begin::Header-->
        <div class="card-header pt-5 border-0 align-items-center justify-content-start gap-5">
          <!--begin::Icon-->
          <div class="m-0">
            <!--begin::Svg Icon | path: icons/duotune/graphs/gra001.svg-->
            <span class="svg-icon svg-icon-2hx svg-icon-gray-600">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M14 3V21H10V3C10 2.4 10.4 2 11 2H13C13.6 2 14 2.4 14 3ZM7 14H5C4.4 14 4 14.4 4 15V21H8V15C8 14.4 7.6 14 7 14Z" fill="currentColor" />
                <path d="M21 20H20V8C20 7.4 19.6 7 19 7H17C16.4 7 16 7.4 16 8V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z" fill="currentColor" />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </div>
          <!--end::Icon-->
          <!--begin::Section-->
          <div class="d-flex flex-column my-3">
            <!--begin::Number-->
            <span class="fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2">{{ data.total_amount_paid_period / 100 | formatMoney }}</span>
            <!--end::Number-->
            <!--begin::Follower-->
            <div class="m-0">
              <span class="fw-semibold fs-6 text-gray-400">Valor Total Recebido</span>
            </div>
            <!--end::Follower-->
          </div>
          <!--end::Section-->
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body d-flex pt-2 pb-4">
          <!--begin::Chart-->
          <DoughnutChart
            :chart-options="{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'right'
                }
              }
            }"
            :chart-data="chartDataTotalPaid"
            chart-id="doughnut-chart"
            dataset-id-key="label"
            :height="100"
            :width="300"
          />
          <!--end::Chart-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Card widget 2-->
    </div>
    <div class="col-12 col-lg-3">
      <!--begin::Card widget 2-->
      <div class="card h-lg-100">
        <!--begin::Body-->
        <div class="card-body d-flex justify-content-between align-items-start flex-column">
          <!--begin::Icon-->
          <div class="m-0">
            <!--begin::Svg Icon | path: icons/duotune/graphs/gra001.svg-->
            <span class="svg-icon svg-icon-2hx svg-icon-gray-600">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.3" d="M14 3V21H10V3C10 2.4 10.4 2 11 2H13C13.6 2 14 2.4 14 3ZM7 14H5C4.4 14 4 14.4 4 15V21H8V15C8 14.4 7.6 14 7 14Z" fill="currentColor" />
                <path d="M21 20H20V8C20 7.4 19.6 7 19 7H17C16.4 7 16 7.4 16 8V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z" fill="currentColor" />
              </svg>
            </span>
            <!--end::Svg Icon-->
          </div>
          <!--end::Icon-->
          <!--begin::Section-->
          <div class="d-flex flex-column my-7">
            <!--begin::Number-->
            <span class="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{{ data.commission_receivable_period / 100 | formatMoney }}</span>
            <!--end::Number-->
            <!--begin::Follower-->
            <div class="m-0">
              <span class="fw-semibold fs-6 text-gray-400">Comissão a receber</span>
            </div>
            <!--end::Follower-->
          </div>
          <!--end::Section-->
        </div>
        <!--end::Body-->
      </div>
      <!--end::Card widget 2-->
    </div>
  </div>
</template>

<script>

import { Doughnut as DoughnutChart } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'SalesSummary',
  props: ['data'],
  components: {
    DoughnutChart
  },
  data () {
    return {
    }
  },
  computed: {
    chartDataTotalSold () {
      if (!this.data) return []

      return {
        labels: ['Primeira compra', 'Renovação'],
        datasets: [
          {
            backgroundColor: ['#FED615', '#ccaa4c'],
            data: [this.data.first_purchase / 100, this.data.renovation / 100]
          }
        ]
      }
    },
    chartDataTotalPaid () {
      if (!this.data) return []

      return {
        labels: ['Recebido', 'Não recebido'],
        datasets: [
          {
            backgroundColor: ['#FED615', '#ccaa4c'],
            data: [this.data.total_amount_paid_period / 100, this.data.total_amount_unpaid_period / 100]
          }
        ]
      }
    }
  }
}
</script>
